<template>
    <v-container fill-height>
      <div style="height: 100px"></div>
      <v-overlay :value="overlayLoading">
        <v-progress-circular
            indeterminate
            size="65"
        ></v-progress-circular>
      </v-overlay>
      <v-row justify="center" align="center">
<!--          <b>Phylum 분석</b>
        -->
      </v-row>
      <v-row justify="center" align="center">
        <vs-select style="float:none; margin:0 auto"
                   width="250px"
                   autocomplete
                   @input="getByAgeDiversitySpecies"
                   class="center-block"
                   v-model="selectSpecies"
        >
         <vs-select-item :key="index" :value="item.value" :text="item.text"
                                     v-for="(item,index) in selectList.speciesList"/>
        </vs-select>
      </v-row>
      <v-row ref="c1" justify="start" align="center">
        <v-col cols="auto">
          <v-chart :options="byAgeDiversityBySpecies" :initOptions="chartSize" />
        </v-col>
      </v-row>
      <v-row ref="c1" justify="start" align="center" style="height: 100px">
        <v-col cols="auto">
        </v-col>
      </v-row>
      <v-row justify="start" align="center">
        <v-col cols="auto">
          <v-chart :options="freqDistributionListBySpeciesChart" :initOptions="chartSize" />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    data : Object,
    code : String
  },
  data() {
    return {
      overlayLoading: false,
      chartSize : { width: 800, height: 500 },
      selectSpecies: 'acetethylicum',
    }
  },
  methods: {
    ...mapActions(['getByAgeDiversitySpecies', 'getSelectList']),
  },
  async mounted () {
    await this.getSelectList();
    this.chartSize.width = this.$refs.c1.clientWidth;

    window.addEventListener('resize',() => {
      this.chartSize.width = this.$refs.c1.clientWidth;
    })

    this.overlayLoading = true;
    await this.getByAgeDiversitySpecies(this.selectSpecies);
    this.overlayLoading = false;
  },
  destroyed(){
    window.removeEventListener("resize", ()=>{});
  },
  computed : {
    byAgeDiversityBySpecies() {
      let byAgeChart = this.$store.state.byAgeDiversityBySpecies;
      if(typeof(byAgeChart.tooltip) === 'object'){
        byAgeChart.tooltip.formatter = (p, a, b) => {
          console.log(p , a , b)
          return `Value&nbsp;: ${p.data[1].toFixed(2)}<br />
                  SampleId&nbsp;: ${p.data[2]}<br />
                  Age&nbsp;: ${p.data[0]}` ;
        }
        // 이벤트를 2개 넣을 수 없음 , 그렇기 때문에 클릭 이벤트 제거
        byAgeChart.tooltip.triggerOn = "mousemove";
        byAgeChart.selectedMode = "single";
      }
      return byAgeChart;
    },
    freqDistributionListBySpeciesChart() {
      console.log(this.$store.state.freqDistributionListBySpeciesChart);
      return this.$store.state.freqDistributionListBySpeciesChart;
    },
    selectList() {
      return this.$store.state.selectList;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../theme/reportScss';
</style>